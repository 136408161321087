.App {
  text-align: center;
  color:rgb(43, 43, 43);
  background: rgb(255, 255, 255);
  /* background-color: rgb(117, 117, 117);  */
  min-height:100vh;
  max-width:100%;
  overflow-x:hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
table{
  border: 1px solid rgb(41, 41, 41);
}
tr {
  font-size: 1.4em;
  
}
td{
  min-width: 130px;
  font-family: 'Optima';
  font-size: 0.9em;
  padding: 4px 20px;
  /* margin: 0px 20px; */
}
.row>*{
  border: 1px solid;
}
.container {
  max-width: 100%; 
}
.App-header {
  background-color: rgb(56,56,56);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(25, 25, 25, 0.832);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  scroll-margin-top: 100px;
}
.nav-link{
  color:white !important;
}
.nav-ytlogo{
  margin-left: 25px;
}
.footer-bottomlogo{
  margin-left: 45px;
}
#iypo-home{
  color:rgb(36, 127, 144) !important;
}
#iypo-nav-winner{
  color:rgb(36, 127, 144) !important;
}
.navbar-black{
  color:rgb(36, 127, 144) !important;
}
.nav-container{
  display: flex;
  flex-direction: column; 
  width: 100%;
  /* min-height:50px; */
  
}
.nav-column{
  flex-direction:row;
}
.nav-item{
  float: left;
}
.home-frame{
  width:100%;
  height:100%;
  position:static;
  background-color:rgba(140, 139, 139, 0.592);
  opacity:0.73;
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
}
.home-comp-title{
  color: rgb(0, 0, 0);
  margin-top: 4%;
  font-size: 3rem;
  font-weight: bolder;
  font-family: "Optima";
  margin-bottom: 10px;
}
.home-comp-subtitle{
  color: rgb(0, 0, 0);
  margin-top: 4%;
  font-size: 2.5rem;
  font-weight: bolder;
  font-family: "Optima";
  margin-bottom: 10px;
}
.home-comp-subtitle2{
  color:rgb(0, 0, 0);
  font-size:2rem;
  font-family:"Optima";
  font-weight: bolder;
  margin-bottom:7px;
}
.vid-wrapper{ 
  width:100%;
  /* background: rgba(0,0,0,1); */
  min-height:80vh;
  
}
.home-column-container{
  display:flex;
  flex-direction: row;
  max-height:100vh;
}
.home-column-strip{
  width:33.33%;
  height:100%;
  min-height:95vh;
  border-right:2px solid black;
}
.home-textstrip{
  padding: 5%;
  position: relative;
  font-size: 1.25rem;
  text-align: justify;
  color:rgb(128, 106, 0);
  font-weight: bolder;
}
.home-strip-background-2{
  position: relative; 
}
.home-strip-background-2::before {    
  content: "";
  background-image: url("../public/assets/home-stirip-2.jpg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}
.home-strip-background-3{
  position: relative; 
}
.home-strip-background-3::before {    
  content: "";
  background-image: url("../public/assets/home-stirip-3.PNG");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}
.vid-container{
  /* object-fit: inherit; */
  width: 130%;
  height: 85%;
  min-height:38vh;
  margin-top: 0%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; 
  transform: translate(-7%,0);
  filter: brightness(1);
}
.vid-container-icmt{
  /* object-fit: inherit; */
  width: 100%;
  z-index: 2; 
  padding: 40px;
}
.front-layer-white{
  z-index:1;
  font-weight: bolder;
  position:absolute;
  color: rgba(56, 56, 56,1);
  /* background-color: rgba(255,255,255,0.4); */
  margin-top:70vw;
  width: 100%;
  height: 20%;
  
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.home-content-div{
  min-height: 80vh;
  padding-top: 1%;
}
.category-div{
  min-height: 80vh;
  padding-top: 1%;
  position: relative;
  z-index:2;
}
#myVideo {
  position: fixed;
  z-index: -1;
  filter:opacity(0.33);
  left: 0;
  top: 0;
  min-width: 100%; 
  min-height: 100%;
  transform: translate(-200px,0px) rotateY(180deg) ;
  -webkit-transform:translate(-200px,0px) rotateY(180deg); /* Safari and Chrome */
  -moz-transform:translate(-200px,0px) rotateY(180deg); /* Firefox */
}
#cat-content {
  background-color: rgba(255, 255, 255, 0) !important;
  /* z-index:2; */
  position:relative;
  z-index: 2;
  /* padding:0% 1%; */
  margin-bottom: 3px;
}
.home-poster{
  margin-top: 100px;
  /* margin-bottom:3%; */
  border-radius: 0px;
  min-height: 50vh;
}
.home-poster-img{
  max-height:100vw;
  background: whitesmoke;
  padding: 2% 2%;
  border-radius: 0px;
}
.companyintro-section{
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;
  border-style: solid;
  border-width: 2px;
  border-color:rgb(218, 218, 218);
  /* margin-left: 2.7%;
  margin-right: 2.7%; */
  border-radius:7px;
  padding-top:30px;
  /* padding-left:20px; */
  padding-bottom:23px;
  margin-bottom:7px;
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
}

.home-content-box{
  animation: fadeIn 1.5s;
  margin-top: 3%;
  margin-bottom: 1%;
  /* margin-left: 5%;
  margin-right: 5%; */
  color:rgba(0,0,0,0.9);
  background-color: rgb(56,56,56);
  text-align: justify;
}
.word-wrapper{
  padding-left:20px;
  padding-right:20px;
}
.content-title{
  font-style:italic;
  font-weight:bold;
  text-align: justify;
  font-size: 28px;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-brand{
  margin-left: 30px;
}
.navbar-toggler{
  margin-right:10px;
}
.footer{
  z-index: 4;
  background-color: rgba(0,0,0,1);
  align-items: center;
  justify-content: center;
  color:#ffffff;
  /* min-height: 60px; */
  position:relative;
  /* bottom: -92px; */
  width:100%;
}
.img-wrapper{
  margin-left: 20px;
  /* min-height:60px; */
}
.footer-img-wrapper{
  
  animation: fadeIn 1.5s;
}
.img-align{
  float:left;
  min-width:58px;
  min-height:58px;
 
}
.img-align:hover{
  cursor: pointer;
}
.footer-text-center{
  align-items: flex-end;
  text-align: center ;
  /* margin-right: 20px;
  margin-top: 20px !important; */
  z-index:-2;
}
.dropdown-right{
  position: absolute;
  right:-100px;
}
.fullwidth{
  z-index: 10;
}
.rednav{
  box-shadow: 0px 3px rgba(235, 1, 1, 0.315) !important;
  background-color: rgba(130, 0, 0,1) !important;
  font-size: 1.7rem;
  height:80px;
}
.navbar-collapse{
  background: rgba(0,0,0,0);
}
.sticky-top{
  height: 100% !important;
}
.about-title{
  /* color:white; */
  font-size:2em;
  font-family:"Optima";
}
.about-text{
  font-size:1.6em;
  font-family:"Optima";
  padding:0% 2%;
}
.about-poster-wrapper{
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about-poster-item{
  width:calc( 20% - 20px);
  margin:10px;
  cursor:pointer;
}
.about-poster-item:hover{
  transform: scale(1.05);
  transition: transform .4s ease-out; 
}
.about-smalltext{
  font-size: 12px;
  margin-bottom: 100px;
  margin-left:3%;
  margin-right:3%;
}
.qrcode{
  width:45%;
  margin-top:10px;
  margin-bottom:30px;
}
.apply-payment-boxtitle{
  font-size: 1em;
  width:40%;
}
.apply-payment-boxcontent{
  font-size: 1.1em;
  width:60%;
}
.apply-body-font{
  font-size: 1.4em;
}
.award-laureate-table{
  width:80%
}
.indexpage-card{
  width:40%;
  margin:auto;
  cursor: pointer;
}
.indexpage-cardwrapper{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}
.winners-banner{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right:20px;
  width:100%;
  height:50vh;
  object-fit: contain;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 40px !important;
}
.winners-title{
  display:flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.3rem;
}
.iyfao-cat-cell{
  width:33%;
}
.winner-body{
  display:block;
  flex-direction:row;
  margin-top:35px;
  width:100%;
}
.winner-card{
  width:31%;
  justify-content:space-evenly;
  margin:5px 10px;
}
.magazine-wrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top:30px;
  width:100vw;
}
.magazine-img{
  width:60%;
}
.teacherlist-item{
  cursor:pointer;
}
.teacherlist-item:hover{
  color:rgb(210, 131, 14);
}
@media (max-width:700px) {
            .vid-wrapper{ 
              width:100%;
              /* background: rgba(0,0,0,1); */
              min-height:50vh;
              
            }
            .nav-ytlogo{
             display:none;
            }
            .footer-bottomlogo{
              margin-left: 25px;
            }
            .magazine-img{
              width:90%;
            }
            .about-poster-wrapper{
              flex-direction: column;
            }
            .about-poster-item{
              width:calc( 100% - 20px);
              margin:10px;
            }
            .about-poster-item:hover{
              transform: none;
              transition: none;
            }
            .qrcode{
              width:100%;
              margin-top:2%;
              margin-bottom:4%;
            }
            .footer{
              position:fixed;
              bottom:0;
            }
            .home-column-container{
              display:flex;
              flex-direction: column;
            }
            .home-column-strip{
              width:100%;
              /* height:40vh; */
              /* max-height:40vh; */
              /* min-height:auto; */
              /* border-right:2px solid black; */
            }
            .rednav{
              box-shadow: 0px 3px rgba(235, 1, 1, 0.315) !important;
              background-color: rgba(130, 0, 0,1) !important;
              font-size: 0.8rem;
              height:80px;
            }
            .winner-body{
              display:flex;
              flex-direction:column;
              margin-top:25px;
              width:100%;
            }
            .winner-card{
              width:100%;
              display:flex;
              flex-direction: column;
              margin:5px 5px;
            }
            .home-poster{
              margin-top: 0;
            }
            .home-poster-img{
              margin-bottom: 20%;
            }
            .indexpage-cardwrapper{
              flex-direction: column;
            }
            .indexpage-card{
              width:80%;
              margin:auto;
            }
            .award-laureate-table{
              width:95%
            }
            .navbar-brand{
              margin-left: 30px;
              width:27%;
            }
            #myVideo {
              position: fixed;
              /* filter:opacity(0.33); */
              left: 0;
              top: 0;
              min-width: 100%; 
              min-height: 100%;
              transform: translate(-450px,0px) rotateY(180deg) ;
              -webkit-transform:translate(-450px,0px) rotateY(180deg); /* Safari and Chrome */
              -moz-transform:translate(-450px,0px) rotateY(180deg); /* Firefox */
            }
            .home-goldbutton {
              position: relative;
              color:rgb(255, 248, 211) !important;
              font-weight:bold;
              background: linear-gradient(to bottom right,rgba(156, 78, 0, 1), rgba(109, 62, 0, 1));
              border-color:rgb(200, 193, 161) !important;
              width: 58%;
              min-width: 160px;
              min-height:8vw;
              font-size: 0.3vw;
              border-radius: 10px;
              padding-top:1%;
              padding-bottom:1%;
              padding-left:1%;
              padding-right:1%;
              font-family: "Optima";
            }
            .home-comp-title{
              font-weight:"bolder";
              color: rgba(0,0,0,0.9);
              font-size: 4.6vw;
              margin-top: 14%;
              font-family: "Optima";
              margin-bottom: 1%;
            }
            .home-comp-subtitle{
              color: rgba(0,0,0,0.9);
              /* font-size: 3.6vw; */
              margin-bottom: 1.6%;
              font-size:1.7rem;
              font-family:"Optima";
            }
            .home-comp-subtitle2{
              color:rgba(0,0,0,0.9);
              /* font-size:3.6vw; */
              font-size:1.7rem;
              font-family:"Optima";
              margin-bottom:4px;
            }
            .apply-payment-boxtitle{
              font-size: 0.68em;
              width:50%;
            }
            .apply-payment-boxcontent{
              font-size: 0.75em;
              width:50%;
            }
            .apply-body-font{
              font-size: 1.1em;
            }
            .jury-profile{
              float:none;
              min-width: 100%;
              /* flex-direction: column; */
            }
            .jury-flag{
              border:1px solid white;
              min-width:27%;
            }
            .jury-text{
              float:left;
              min-width:100%;
              padding:0% -1%;
              margin-left: 0px !important;
              text-align:justify;
              margin-top: 4%;
            }
            #goldbutton-apply{
              color:rgb(255, 248, 211) !important;
              font-weight:bold;
              border-color:rgb(200, 193, 161) !important;
              height: 10vh;
              min-height: 70px;
              min-width:77%;
              font-size: 2vw;
              border-radius: 10px;
              margin-top: 1%;
              padding-top:1.5%;
              padding-bottom:1.5%;
              padding-left:1.5%;
              padding-right:1.5%;
              font-family: "Optima";
              background: linear-gradient(to bottom right,rgba(156, 0, 0, 0.7), rgba(109, 0, 0, 0.9));
            }
            .about-goldbutton {
              color:rgb(255, 248, 211) !important;
              font-weight:bold;
              background: linear-gradient(to bottom right,rgba(156, 0, 0, 0.7), rgba(109, 0, 0, 0.9));
              border-color:rgb(200, 193, 161) !important;
              min-height: 10vh;
              font-size: 2.8vw;
              border-radius: 10px;
              padding-top:1.5%;
              padding-bottom:1.5%;
              padding-left:1.5%;
              padding-right:1.5%;
              font-family: "Optima";
            }
            .about-title{
              font-size:1.8em;
              font-family:"Optima";
            }
            .about-text{
              font-size:1.2em;
              font-family:"Optima";
              padding:0% 1%;
            }
            .front-layer-white{
              /* z-index:1; */
              position:absolute;
              color: rgba(255, 255, 255, 0.414);
              margin-top:60vw;
              width: 100%;
              height: 90%;
              
            }
            .vid-container{
              /* object-fit: inherit; */
              width: 190%;
              /* overflow:hidden; */
              /* min-height: 200%; */
              /* min-height:70vh; */
              margin-top: 0%;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 2; 
              transform: translate(-7%,0);
              filter: brightness(1);
            }
            .home-break{
              max-height:2%;
            }
            .cardwrapper{
              display:block;
              /* justify-content: space-between; */
              width:100%;
            }
            .home-card{
              width:calc(100% - 20px);
              align-items: center;
              justify-content: center;
              min-height: 300px;
              padding:5px 5px;
              margin-left: 5px;
              margin-right: 5px;
              margin-bottom: 200px;
              border-style: solid;
              border-width: 1px;
              border-color:rgba(130,0,0,1);
              background-color: rgba(240,240,240,1);
              border-radius: 7px;
              /* box-shadow: 1px 2px #909397; */
              animation: fadeIn 1.5s;
            }
}
@media (min-width:500px) {
  .vid-wrapper{ 
    width:100%;
    /* background: rgba(0,0,0,1); */
    min-height:none;
    
  }
 
  .cardwrapper{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .home-card{
    width:calc(100% - 20px);
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding:5px 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    /* margin-bottom:10px; */
    border-style: solid;
    border-width: 3px;
    border-radius: 3px;
    animation: fadeIn 1.5s;
    border-color:rgba(130,0,0,1);
    background-color: rgba(240,240,240,1);
    color:rgba(43,43,43,1);
  }
}


img{
  border-radius: 3%;
}
.cardphoto{
  margin-bottom: 30px;
  border-radius: 7px;
}
.cardhead{
  margin-top: 45px;
  text-align: center;
  font-size: large;
  font-weight: bolder;
}
.cardcontent{
  font-family: "Optima";
  font-weight: bold;
  margin-top: 45px;
  margin-left:10px;
  margin-right:10px;
  margin-bottom:40px;
  text-align: justify;
}
.side-margin-15{
  margin-left: 1%;
  margin-right:1%;
}
.accor1 > .accordion-button{
  background-color: #795d0086 !important;
}
.accor1{
  background-color: #795d0086 !important;
}
.accor2 > .accordion-button{
  background-color: #a382169c !important;
}
.accor2{
  background-color: #a382169c !important;
}
.accor3 > .accordion-button{
  background-color: #d3ae358f !important;
}
.accor3{
  background-color: #d3ae358f !important;
}
.accor4 > .accordion-button{
  background-color: #fdce338a !important;
}
.accor4{
  background-color: #fdce338a !important;
}
.embed-page{
  width: 100%;
  height: 500px;
}
.embed-page .widgetTitle{
 background-color: rgb(56,56,56) !important;   
}
.aboutus-content-box{
  animation: fadeIn 1.5s;
  margin-top: 3%;
  margin-bottom: 1%;
  /* padding-left: 5%;
  padding-right: 5%; */
  text-align: justify;
}
.boldtext{
  font-weight:bolder;
  font-style:normal;
  font-family:Arial, Helvetica, sans-serif;
}
.min-height-600{
  min-height: 600px;
  height:40%;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.img1{
  /* background-image: url("../public/assets/home-card-1.jpg");
  background-size: 1000px; */
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
  width: 100%;
  height: 100%;
  margin-bottom:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-size:cover;
  /* box-shadow:inset 0 0 0 1000px rgba(255, 255, 255, 0.7); */
}
.img2-100{
  /* background-image: url("../public/assets/upindice.jpg");
  background-size: 1000px; */
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
  width: 100%;
  height: 100%;
  margin-bottom:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-size:cover;
  /* box-shadow:inset 0 0 0 1000px rgba(255, 255, 255, 0.7); */
}
.img2{
  /* background-image: url("../public/assets/upindice.jpg");
  background-size: 1000px; */
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
  width: 95%;
  height: 100%;
  margin-bottom:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-size:cover;
  /* box-shadow:inset 0 0 0 1000px rgba(255, 255, 255, 0.7); */
}
.img3{
  /* background-image: url("../public/assets/lamboyacht.jpeg");
  background-size: 1000px; */
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
  width: 100%;
  height: 100%;
  margin-bottom:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-size:cover;
  /* box-shadow:inset 0 0 0 1000px rgba(255, 255, 255, 0.7); */
}
.img4{
  /* background-image: url("../public/assets/lamboyacht3.jpeg"); */
  /* background-size: 1000px; */
  color:rgb(218, 218, 218);
  background-color: rgb(56,56,56);
  width: 100%;
  height: 100%;
  margin-bottom:20px;
  padding-top:10px;
  padding-bottom:10px;
  background-size:cover;
  /* box-shadow:inset 0 0 0 1000px rgba(255, 255, 255, 0.7); */
}
.tagline{
  font-style: italic;
  font-weight: lighter;
  margin-left: 1rem;
  margin-top: 1rem;
  float:left;
}
main.respTbl{
  background: rgb(56,56,56) !important;
}
main.widgetTitle {
  background-color: rgb(56,56,56) !important;
}
.home-goldbutton {
  position: relative;
  color:rgb(255, 248, 211) !important;
  font-weight:bold;
  /* background-color:rgba(255,255,255,0.15) !important; */
  background: linear-gradient(to bottom right,rgb(194, 29, 0), rgb(109, 24, 0));
  border-color:rgb(200, 193, 161) !important;
  width: 52%;
  min-width: 160px;
  height: 6vw;
  font-size: 2vw;
  border-radius: 10px;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:1.5%;
  padding-right:1.5%;
  font-family: "Optima";
}
.home-whitebutton{
  position: relative;
  color:rgb(255, 248, 211) !important;
  font-weight:bold;
  /* background-color:rgba(255,255,255,0.15) !important; */
  background: linear-gradient(to bottom right,rgb(172, 231, 241), rgb(66, 133, 189));
  border-color:rgb(200, 193, 161) !important;
  width: 52%;
  min-width: 160px;
  height: 6vw;
  font-size: 2vw;
  border-radius: 10px;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:1.5%;
  padding-right:1.5%;
  font-family: "Optima";
}
.about-goldbutton {
  color:rgb(255, 248, 211) !important;
  font-weight:bold;
  background: linear-gradient(to bottom right,rgba(156, 0, 0, 0.7), rgba(109, 0, 0, 0.9));
  border-color:rgb(200, 193, 161) !important;
  height: 10vh;
  font-size: 130%;
  border-radius: 10px;
  padding-top:1.5%;
  padding-bottom:1.5%;
  padding-left:1.5%;
  padding-right:1.5%;
  font-family: "Optima";
}
#goldbutton-apply{
  color:rgb(255, 248, 211) !important;
  font-weight:bold;
  height: 10vh;
  min-height: 70px;
  min-width: 65%;
  font-size: 2vw;
  border-radius: 10px;
  margin-top: 1%;
  padding-top:1%;
  padding-bottom:1%;
  padding-left:1.5%;
  padding-right:1.5%;
  font-family: "Optima";
  background: linear-gradient(to bottom right,rgba(156, 0, 0, 0.7), rgba(109, 0, 0, 0.9));
}
.cat-button{
  color:white !important;
  font-size: 1.4em;
  margin-right:5px;
  background-color: rgba(56, 56, 56,0.5) !important;
  border-color:rgba(63, 65, 66,0.5) !important;
  border-radius: 7% !important;
  outline: none;
  background: none;
 box-shadow: none;
 border-radius: 0px;
 font-family: "Optima";
}
.cat-button:hover{
  color:white !important;
  background-color: rgba(56, 56, 56,0.85) !important;
  border-color:rgba(81, 81, 81,0.85) !important;
  border-radius: 7% !important;
  outline: none;
  background: none;
 box-shadow: none;
 border-radius: 0px;
 font-family: "Optima";
}
.cat-button-selected{
  color:white !important;
  font-size: 1.4em;
  margin-right:5px;
  background-color: rgba(43, 42, 42,0.85) !important;
  border-color:rgba(63, 65, 66,0.85) !important;
  border-radius: 7% !important;
  outline: none;
  background: none;
 box-shadow: none;
 border-radius: 0px;
 font-family: "Optima";
}
.jury-profile{
  float: left;
  width:34%;
}
.jury-flag{
  border:1px solid white;
  width:20%;
}
.jury-text{
  float:left;
  width:60%;
  margin-left:4%;
  text-align:justify;
}